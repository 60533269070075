<template>
    <div>
        <div class="main">
            <div class="search">
                <div class="refresh" @click="refresh"><i class="fa fa-refresh"></i></div>
                <el-select size="mini" style="width: 100px" v-model="selectedStatus" placeholder="상태선택"
                           @change="changeStatus">
                    <el-option value="wait" label="베팅대기"></el-option>
                    <el-option value="open" label="베팅가능"></el-option>
                    <el-option value="start" label="베팅마감"></el-option>
                    <el-option value="end" label="종료"></el-option>
                </el-select>
              <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="game.display" placeholder="숨김여부선택"
                         @change="changeStatus">
                <el-option  :value="managerConst.YES" label="정상경기"></el-option>
                <el-option  :value="managerConst.NOT" label="숨겨진경기"></el-option>
              </el-select>
                <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="selectedIsinplay"
                           placeholder="경기타입"
                           @change="changeIsInplay">
                    <el-option value="sports" label="스포츠"></el-option>
                    <el-option value="inplay" label="실시간"></el-option>
                </el-select>
                <el-select size="mini" style="width: 100px;margin-left: 5px"
                           placeholder="종목"
                           v-model="game.gameTypeId"
                           @change="gameTypeSelected" value="">
                    <el-option aria-keyshortcuts="0" :value="null" label="전체"></el-option>
                    <el-option v-for="item in gameTypeList" :key="item.id" :value="item.id"
                               :label="item.typeName"></el-option>
                </el-select>
                <el-popover v-if="game.isinplay === managerConst.Game.INPLAY_NOT"
                            placement="bottom-end"
                            width="1680"
                            visible-arrow="true"
                            trigger="click"
                            popper-class="bg-title"
                            v-model="visible">
                    <div class="ongoingleagues">
                        <div class="lg">
                            <el-button @click="leagueSelected(null)" size="mini" type="warning">전체리그</el-button>
                        </div>
                        <div class="lg">
                            <div v-for="(item,index) in leagueList"
                                 v-if="item.gameTypeId === managerConst.GameType.BASKETBALL" :key="index"
                                 @click="leagueSelected(item.id)">
                                <game-type-image-comp :game-type-id="item.gameTypeId"></game-type-image-comp>
                                <img :src="item.imageUrl"
                                     style="width: 28px;height:20px;vertical-align: middle">
                                <span style="margin-left: 5px">{{item.leagueName}}</span>
                                <span>({{item.gcount}})</span>
                            </div>
                        </div>
                        <div class="lg">
                            <div v-for="(item,index) in leagueList"
                                 v-if="item.gameTypeId === managerConst.GameType.BASEBALLL" :key="index"
                                 @click="leagueSelected(item.id)">
                                <game-type-image-comp :game-type-id="item.gameTypeId"></game-type-image-comp>
                                <img :src="item.imageUrl"
                                     style="width: 28px;height:20px;vertical-align: middle">
                                <span style="margin-left: 5px">{{item.leagueName}}</span>
                                <span>({{item.gcount}})</span>
                            </div>
                        </div>
                        <div class="lg">
                            <div v-for="(item,index) in leagueList"
                                 v-if="item.gameTypeId === managerConst.GameType.VOLLEYBALL" :key="index"
                                 @click="leagueSelected(item.id)">
                                <game-type-image-comp :game-type-id="item.gameTypeId"></game-type-image-comp>
                                <img :src="item.imageUrl"
                                     style="width: 28px;height:20px;vertical-align: middle">
                                <span style="margin-left: 5px">{{item.leagueName}}</span>
                                <span>({{item.gcount}})</span>
                            </div>
                        </div>
                        <div class="lg">
                            <div v-for="(item,index) in leagueList"
                                 v-if="item.gameTypeId === managerConst.GameType.ICEHOCKEY" :key="index"
                                 @click="leagueSelected(item.id)">
                                <game-type-image-comp :game-type-id="item.gameTypeId"></game-type-image-comp>
                                <img :src="item.imageUrl"
                                     style="width: 28px;height:20px;vertical-align: middle">
                                <span style="margin-left: 5px">{{item.leagueName}}</span>
                                <span>({{item.gcount}})</span>
                            </div>
                        </div>
                        <div class="lg">
                            <div v-for="(item,index) in leagueList"
                                 v-if="item.gameTypeId === managerConst.GameType.ESPORTS" :key="index"
                                 @click="leagueSelected(item.id)">
                                <game-type-image-comp :game-type-id="item.gameTypeId"></game-type-image-comp>
                                <img :src="item.imageUrl"
                                     style="width: 28px;height:20px;vertical-align: middle">
                                <span style="margin-left: 5px">{{item.leagueName}}</span>
                                <span>({{item.gcount}})</span>
                            </div>
                        </div>
                        <div class="lg">
                            <div v-for="(item,index) in leagueList"
                                 v-if="item.gameTypeId === managerConst.GameType.SOCCER" :key="index"
                                 @click="leagueSelected(item.id)">
                                <game-type-image-comp :game-type-id="item.gameTypeId"></game-type-image-comp>
                                <img :src="item.imageUrl"
                                     style="width: 28px;height:20px;vertical-align: middle">
                                <span style="margin-left: 5px">{{item.leagueName}}</span>
                                <span>({{item.gcount}})</span>
                            </div>
                        </div>

                        <div class="lg">
                            <div v-for="(item,index) in leagueList"
                                 v-if="item.gameTypeId === managerConst.GameType.OTHER" :key="index"
                                 @click="leagueSelected(item.id)">
                                <game-type-image-comp :game-type-id="item.gameTypeId"></game-type-image-comp>
                                <img :src="item.imageUrl"
                                     style="width: 28px;height:20px;vertical-align: middle">
                                <span style="margin-left: 5px">{{item.leagueName}}</span>
                                <span>({{item.gcount}})</span>
                            </div>
                        </div>
                    </div>
                    <el-button
                            size="mini" style="margin-left: 5px" slot="reference">리그보기
                    </el-button>
                </el-popover>

                <el-select size="mini" style="width: 120px;margin-left: 5px"
                           placeholder="게임배열" v-model="ob"
                           @change="changeOrderby">
                    <el-option :value="null" label="시간순"></el-option>
                    <el-option value="count" label="베팅개수 순서"></el-option>
                    <el-option value="cash" label="베팅금액 순서"></el-option>
                </el-select>
                <el-select v-if="game.isinplay !== managerConst.Game.INPLAY_YES" size="mini"
                           style="width: 180px;margin-left: 5px"
                           placeholder="게임배열" v-model="dispalyandkind"
                           @change="changeDisplayAndKind">
                    <el-option value="disp1-0" label="전체"></el-option>
                    <el-option value="disp1-1" label="풀타임 - 크로스(1x2)"></el-option>
                    <el-option value="disp1-2" label="풀타임 - 크로스(핸디캡)"></el-option>
                    <el-option value="disp1-3" label="풀타임 - 크로스(오버언더)"></el-option>
                    <el-option value="disp3-1" label="스페셜 - 크로스(1x2)"></el-option>
                    <el-option value="disp3-2" label="스페셜 - 크로스(핸디캡)"></el-option>
                    <el-option value="disp3-3" label="스페셜 - 크로스(오버언더)"></el-option>
                    <el-option value="disp4-1" label="라이브"></el-option>
                </el-select>

                <el-popover v-if="game.status !== managerConst.Game.STATUS_END"
                            placement="bottom"
                            popper-class="bg-title"
                            width="450" style="margin-left: 5px">
                    <div>
                        <el-button size="mini" type="warning"
                                   @click="updateGameStatus(managerConst.Game.STATUS_CHECKING)">[대기]상태 로 변경
                        </el-button>
                        <el-button size="mini" type="warning"
                                   @click="updateGameStatus(managerConst.Game.STATUS_OPEN)">[가능]상태 로 변경
                        </el-button>
                        <el-button size="mini" type="warning"
                                   @click="updateGameStatus(managerConst.Game.STATUS_STARTING)">[마감]상태 로 변경
                        </el-button>
                    </div>
                    <el-button size="mini" type="warning" slot="reference">상태처리</el-button>
                </el-popover>
                <el-popover v-if="game.status !== managerConst.Game.STATUS_END"
                            placement="bottom"
                            popper-class="bg-title"
                            width="660" style="margin-left: 5px">
                    <div class="btns">
                        <el-button size="mini" type="warning"
                                   @click="cancelGame(managerConst.Game.GAME_RESULT_CANCELED)">선택경기 [취소]처리
                        </el-button>
                        <el-button size="mini" type="warning"
                                   @click="cancelGame(managerConst.Game.GAME_RESULT_SPECIAL)">선택경기 [적특]처리
                        </el-button>
                        <el-button size="mini" type="danger" @click="gameResultReset">선택경기 [결과초기화]</el-button>
                        <el-button size="mini" type="success" @click="confirmResult">결과적용하기</el-button>
                        <el-button size="mini" type="success" @click="liquidation">베팅정산</el-button>
                    </div>
                    <el-button size="mini" type="info" slot="reference">결과처리</el-button>
                </el-popover>
                <el-popover
                        placement="bottom"
                        popper-class="bg-title"
                        width="280" style="margin-left: 5px">
                    <div>
<!--                        <el-button size="mini" type="danger" @click="updateGameDisplay(managerConst.DISABLE)">-->
<!--                            [삭제]하기-->
<!--                        </el-button>-->
                        <el-button size="mini" type="success" @click="editGame(null,managerConst.MODE_SAVE)">기본등록
                        </el-button>
                        <el-button size="mini" type="success" @click="uploadGameExcel">엑셀등록</el-button>
                    </div>
                    <el-button size="mini" type="success" slot="reference">기타</el-button>
                </el-popover>

                <el-select size="mini" style="width: 100px;margin-left: 25px" v-model="game.byField"
                           placeholder="검색조건">
                    <el-option :value="managerConst.Game.SEARCH_BY_GAMEID" label="게임ID"></el-option>
                    <el-option :value="managerConst.Game.SEARCH_BY_HOME_TEAMNAME" label="W1-팀명"></el-option>
                    <el-option :value="managerConst.Game.SEARCH_BY_AWAY_TEAMNAME" label="W2-팀명"></el-option>
                </el-select>
                <el-input v-model="game.searchString" style="width: 150px;margin-left: 2px" size="mini"></el-input>
                <el-button size="mini" type="primary" style="margin-left: 2px" @click="search">검색</el-button>
            </div>

            <div class="data">
                <p class="text-blue" style="width: 100%"> * 리그명 ,팀명 클릭시 수정가능합니다</p>
                <el-table
                        :data="gameList"
                        style="width: 100%"
                        max-height="750"
                        border
                        @selection-change="handleSelectionChange">
                    <!--더보기-->
                    <el-table-column type="expand" label="더보기" width="60">
                        <template slot-scope="props">
                            <el-form label-position="left" class="demo-table-expand">
                                <el-form-item>
                                    {{props.row.homeTeamName}} <span class="text-green">vs</span>
                                    {{props.row.awayTeamName}}
                                </el-form-item>
                                <el-form-item label="ID">
                                    <span>{{ props.row.id }}</span>
                                </el-form-item>
                                <el-form-item label="UC" v-if="props.row.isinplay === managerConst.Game.INPLAY_NOT">
                                    <span>{{ props.row.unifiedCode }}</span>
                                </el-form-item>
                                <el-form-item label="GroupCode"
                                              v-if="props.row.isinplay === managerConst.Game.INPLAY_NOT">
                                    <span>{{ props.row.groupCode }}</span>
                                </el-form-item>
                                <el-form-item label="API ID"
                                              v-if="props.row.isinplay === managerConst.Game.INPLAY_YES">
                                    <span>{{ props.row.apiId }}</span>
                                </el-form-item>
                                <el-form-item label="진행코어"
                                              v-if="props.row.isinplay === managerConst.Game.INPLAY_YES">
                                    <span>{{ props.row.halfStatus }}</span>
                                </el-form-item>
                                <el-form-item label="진행시간"
                                              v-if="props.row.isinplay === managerConst.Game.INPLAY_YES">
                                    <span>{{ props.row.timemm }}"</span>
                                </el-form-item>
                                <el-form-item label="실시간 스코어"
                                              v-if="props.row.isinplay === managerConst.Game.INPLAY_YES"></el-form-item>
                                <el-form-item v-if="props.row.isinplay === managerConst.Game.INPLAY_YES">
                                    <score-comp :data="props.row"></score-comp>
                                </el-form-item>


                                <el-form-item>
                                    <el-button v-if="props.row.status === managerConst.Game.STATUS_END"
                                               @click.native.prevent="initGame(props.row.id,props.row.homeTeamName,props.row.awayTeamName)"
                                               type="danger"
                                               size="small">
                                        초기화
                                    </el-button>
                                    <el-button v-if="props.row.betCashAmount != null && props.row.betCashAmount > 0"
                                               @click.native.prevent="showMessagePanel(props.row)"
                                               type="primary"
                                               size="small">
                                        쪽지보내기
                                    </el-button>
                                    <el-button v-if="props.row.status === managerConst.Game.STATUS_END"
                                               @click.native.prevent="reuploadgame(props.row.id,props.row.homeTeamName,props.row.awayTeamName)"
                                               type="warnning"
                                               size="small">
                                        재등록요청
                                    </el-button>
                                </el-form-item>

                            </el-form>
                        </template>
                    </el-table-column>

                    <!--상태-->
                    <el-table-column
                            fixed
                            label="상태"
                            width="55">
                        <template slot-scope="scope">
                            <span v-if="scope.row.display == managerConst.YES"><i class="fa fa-eye"></i></span>
                            <span v-if="scope.row.display == managerConst.NOT"><i class="fa fa-eye-slash text-gray"></i></span>
                            <span v-if="scope.row.status === managerConst.Game.STATUS_CHECKING">대기</span>
                            <span v-if="scope.row.status === managerConst.Game.STATUS_OPEN">가능</span>
                            <span v-if="scope.row.status === managerConst.Game.STATUS_STARTING">마감</span>
                            <span v-if="scope.row.status === managerConst.Game.STATUS_END">종료</span>
                        </template>
                    </el-table-column>
                    <!--체크박스-->
                    <el-table-column
                            fixed
                            type="selection"
                            width="40">
                    </el-table-column>
                    <!--시작일짜-->
                    <el-table-column
                            fixed
                            label="시작일"
                            width="90">
                        <template slot-scope="scope">
                            <span :class="{'bg-warning' : scope.row.autoclosed === managerConst.NOT}">{{scope.row.startTime|datef('DD일 HH:mm')}}</span>
                        </template>
                    </el-table-column>
                    <!--종목-->
                    <el-table-column
                            fixed
                            label="종목"
                            width="50">
                        <template slot-scope="scope">
                            <game-type-image-comp :game-type-id="scope.row.gameTypeId"></game-type-image-comp>
                        </template>
                    </el-table-column>
                    <!--리그-->
                    <el-table-column
                            fixed
                            label="리그"
                            width="230">
                        <template slot-scope="scope">
                            <div class="text-left">
                                <img :src="scope.row.league.imageUrl"
                                     style="width: 28px;vertical-align: middle">
                                <span style="margin-left: 5px;cursor: pointer"
                                      @click="editLeague(scope.row.leagueId,managerConst.MODE_EDIT)">{{scope.row.league.leagueName}}</span>
                                <span>({{scope.row.leagueId}})</span>
                            </div>
                        </template>
                    </el-table-column>
                    <!--홈팀명-->
                    <el-table-column
                            fixed
                            label="W1"
                            width="150">
                        <template slot-scope="scope">
                            <div class="text-left" @click="editGame(scope.row.id,managerConst.MODE_EDIT)"
                                 style="cursor: pointer">
                                <i class="fa fa-television text-green"
                                   v-if="scope.row.isinplay === managerConst.Game.INPLAY_YES"></i>
                                <i class="fa fa-trophy text-blue"
                                   v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_WIN_HOME"></i>
                                <span v-html="scope.row.homeTeamName"></span>
                                <span v-if="scope.row.totalBetingHomeCash>0" style="display: block;color: blue">{{scope.row.totalBetingHomeCash|comma}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <!--홈팀배당-->
                    <el-table-column
                            fixed
                            label="W1B"
                            width="90">
                        <template slot-scope="scope">
                            <div>{{scope.row.homeTeamOdds}}</div>
                        </template>
                    </el-table-column>
                    <!--무,핸디,오버언더 기준치-->
                    <el-table-column
                            fixed
                            label="D.H.O"
                            width="90">
                        <template slot-scope="scope">
                            <div v-if="scope.row.kind === managerConst.Game.GAME_KIND_1X2">
                                <i class="fa fa-trophy text-blue"
                                   v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_DRAW"></i>
                                <span>{{scope.row.drawOdds}}</span>
                                <span v-if="scope.row.totalBetingDrawCash>0" style="display: block;color: blue">{{scope.row.totalBetingDrawCash|comma}}</span>

                            </div>
                            <div class="text-blue" v-if="scope.row.kind === managerConst.Game.GAME_KIND_HANDICAP">
                                <i class="fa fa-h-square"></i> ({{scope.row.handicapVal}})
                            </div>
                            <div v-if="scope.row.kind === managerConst.Game.GAME_KIND_OVERUNDER">
                                <i class="fa fa-arrow-circle-up text-red"></i>
                                <i class="fa fa-arrow-circle-down text-blue"></i>
                                ({{scope.row.overunderVal}})
                            </div>
                        </template>
                    </el-table-column>
                    <!--원정배당-->
                    <el-table-column
                            fixed
                            label="W2B"
                            width="90">
                        <template slot-scope="scope">
                            <div>{{scope.row.awayTeamOdds}}</div>
                        </template>
                    </el-table-column>
                    <!--원정팀명-->
                    <el-table-column
                            fixed
                            label="W2"
                            width="150">
                        <template slot-scope="scope">
                            <div class="text-right" @click="editGame(scope.row.id,managerConst.MODE_EDIT)"
                                 style="cursor: pointer">
                                <span v-html="scope.row.awayTeamName"></span>
                                <i class="fa fa-trophy text-blue"
                                   v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_WIN_AWAY"></i>
                                <i class="fa fa-television text-green"
                                   v-if="scope.row.isinplay === managerConst.Game.INPLAY_YES"></i>
                                <span v-if="scope.row.totalBetingAwayCash>0" style="display: block;color: blue">{{scope.row.totalBetingAwayCash|comma}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <!--베팅수-->
                    <el-table-column
                            fixed
                            label="베팅수"
                            width="80">
                        <template slot-scope="scope">
                            <div style="width: 100%;padding: 2px 0;cursor: pointer;"
                                 @click="showSportsBetInfo(scope.row)">{{scope.row.betQuantity|comma}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="베팅금"
                            width="100">
                        <template slot-scope="scope">
                            <div style="width: 100%;padding: 2px 0;cursor: pointer;"
                                 @click="showSportsBetInfo(scope.row)">{{scope.row.betCashAmount|comma}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="스코어"
                            width="70">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.status !==  managerConst.Game.STATUS_END"
                                       @click.native.prevent="setScore(scope.row)"
                                       type="text"
                                       size="small">
                                스코어입력
                            </el-button>

                            <div v-if="scope.row.homeTeamScore >-1 &&scope.row.awayTeamScore >-1
                                && scope.row.isinplay === managerConst.Game.INPLAY_NOT">
                                {{scope.row.homeTeamScore + ':' + scope.row.awayTeamScore}}
                            </div>
                            <span class="text-red" style="display: block"
                                  v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_CANCELED">
                                    취소
                                </span>
                            <span class="text-red" style="display: block"
                                  v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_SPECIAL">
                                    적특
                                </span>
                        </template>
                    </el-table-column>

                </el-table>
            </div>
            <div class="pagePanel">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 150, 200]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageTotal">
                </el-pagination>
            </div>
        </div>

        <!--Excel Upload-->
        <el-dialog title="엑셀경기등록" width="20%" :visible.sync="gameExceluploadDialog">

            <el-upload
                    class="upload-demo"
                    ref="upload"
                    :limit="1"
                    :action="uploadAction"
                    :on-success="uploadSuccess"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-change="fileChanged"
                    :file-list="fileList"
                    :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">경기등록 엑셀 파일을 선택하세요</el-button>
            </el-upload>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">취소</el-button>
                <el-button type="primary" @click="submitUpload">확인</el-button>
            </div>
        </el-dialog>

        <!--Excel Upload-->
        <el-dialog width="30%" title="쪽지전송" :visible.sync="sendMessageDialog">
            <h5>경기정보</h5>
            <div style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: nowrap;margin-bottom: 10px;padding: 10px 50px">
                <div style="padding: 0 10px">
                    {{this.gameMessage.game.startTime|datef('MM-DD HH:mm')}}
                </div>
                <div style="padding: 0 10px">
                    {{this.gameMessage.game.homeTeamName}} <span
                        style="display: inline-block;padding: 0 5px;color: red">vs</span>
                    {{this.gameMessage.game.awayTeamName}}
                </div>
            </div>
            <div style="padding: 10px 50px">
                <quill-editor ref="text" v-model="gameMessage.content" class="myQuillEditor" :options="editorOption"
                              @change="onEditorChange($event)"/>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">취소</el-button>
                <el-button type="primary" @click="message2better">확인</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script>
    import Vue from 'vue'
    import {Loading} from 'element-ui';
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import {
        confirmResult, gameResultReset,
        getOnGamingLeagues,
        getSportsGameList, initGame, liquidation, updateGameDisplay, updateGameResultCancel,
        updateStatus
    } from "../../network/manager/sportsRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import {getGameTypeList} from "../../network/manager/gameTypeRequest";
    import managerConst from "../../common/administrator/managerConst";
    import GameTypeImageComp from "../../components/administrator/GameTypeImageComp";
    import ScoreComp from "../../components/administrator/ScoreComp";
    import {uploadFile} from "../../network/manager/commonRequest";
    import SportsScoreToast from "../../components/administrator/SportsPanel/SportsScoreToast";
    import InplayScoreToast from "../../components/administrator/SportsPanel/InplayScoreToast";
    import SportsEditToast from "../../components/administrator/SportsPanel/SportsEditToast";
    import {sendMessageFromGame} from "../../network/manager/messageRequest";
    import SportsBetInfoToast from "../../components/administrator/SportsPanel/SportsBetInfoToast";
    import LeagueEditToast from "../../components/administrator/SportsPanel/LeagueEditToast";

    Vue.prototype.$sportsScoreToast = SportsScoreToast
    Vue.prototype.$inplayScoreToast = InplayScoreToast
    Vue.prototype.$sportsEditToast = SportsEditToast
    Vue.prototype.$sportsBetInfoToast = SportsBetInfoToast
    Vue.prototype.$leagueEditToast = LeagueEditToast

    export default {
        name: "ManagerSportsGame",
        components: {
            ScoreComp, GameTypeImageComp, OnlineUserComp, TodayStatisticComp, ManagerTopbarComp
        },
        mixins: [manager],
        data() {
            return {
                orderByStringASC: 'g.start_time ASC, le.popular DESC, g.home_team_name DESC, g.kind ASC, g.handicap_val ASC, g.overunder_val ASC',
                orderByStringDESC: 'g.start_time DESC, le.popular DESC, g.home_team_name DESC, g.kind ASC, g.handicap_val ASC, g.overunder_val ASC',
                orderByBetCountStringDESC: 'g.bet_quantity DESC , g.start_time ASC, le.popular DESC, g.home_team_name ASC, g.kind ASC, g.handicap_val ASC, g.overunder_val ASC',
                orderByBetCashStringDESC: 'g.bet_cash_amount DESC , g.start_time ASC, le.popular DESC, g.home_team_name ASC, g.kind ASC, g.handicap_val ASC, g.overunder_val ASC',
                game: {
                    status: -1,
                    display: managerConst.YES,
                    displayPosition: -1,
                    kind: 0,
                    isinplay: 0,
                    onlyBet: 0,
                    ids: [],
                    leagueId: null
                },
                pageNum: 1,
                pageSize: 50,
                orderBy: null,
                pageTotal: 0,
                gameTypeList: [],
                gameList: [],
                leagueList: [],
                selectedStatus: 'open',
                selectedIsinplay: 'sports',
                multipleSelection: [],
                visible: false,
                ob: null,
                dispalyandkind: 'disp1-1',
                gameExcelUploadAction: '',
                gameExceluploadDialog: false,
                sendMessageDialog: false,
                fileList: [],
                uploadAction: '/sports/upload_game_excel',
                excelFile: null,
                gameMessage: {gameId: 0, content: '', game: {}},
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],        //加粗，斜体，下划线，删除线
                                ['blockquote', 'code-block'],         //引用，代码块
                                [{'header': 1}, {'header': 2}],               // 标题，键值对的形式；1、2表示字体大小
                                [{'list': 'ordered'}, {'list': 'bullet'}],          //列表
                                [{'script': 'sub'}, {'script': 'super'}],      // 上下标
                                [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
                                [{'direction': 'rtl'}],                         // 文本方向
                                [{'size': ['small', false, 'large', 'huge']}],  // 字体大小
                                [{'header': [1, 2, 3, 4, 5, 6, false]}],         //几级标题
                                [{'color': []}, {'background': []}],          // 字体颜色，字体背景颜色
                                [{'font': []}],         //字体
                                [{'align': []}],        //对齐方式
                                ['clean'],        //清除字体样式
                                ['image', 'video']        //上传图片、上传视频
                            ]
                        }
                    },

                },
            }
        },
        methods: {
            refresh() {
                let status = this.game.status
                let displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_1x2
                let isinplay = this.game.isinplay
                this.game = {
                    status: status,
                    display: managerConst.YES,
                    displayPosition: displayPosition,
                    searchString: null,
                    byField: null,
                    isinplay: isinplay
                }
                this.dispalyandkind = 'disp1-1'
                this.getSportsGame();
            },
            search() {

                let status = this.game.status
                let searchString = this.game.searchString
                let byField = this.game.byField
                let isinplay = this.game.isinplay
                this.game = {
                    status: status,
                    display: null,
                    displayPosition: null,
                    searchString: searchString,
                    byField,
                    isinplay
                }
                this.getSportsGame();

            },
            setOrderByString() {
                if (this.game.status == managerConst.Game.STATUS_END) {
                    this.orderBy = this.orderByStringDESC
                } else {
                    this.orderBy = this.orderByStringASC
                }
            },
            changeDisplayAndKind() {
                switch (this.dispalyandkind) {
                    case "disp1-0":
                        this.game.displayPosition = null
                        this.game.kind = null
                        break;
                    case "disp1-1":
                        this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_1x2
                        this.game.kind = managerConst.Game.GAME_KIND_1X2
                        break;
                    case "disp1-2":
                        this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER
                        this.game.kind = managerConst.Game.GAME_KIND_HANDICAP
                        break;
                    case "disp1-3":
                        this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER
                        this.game.kind = managerConst.Game.GAME_KIND_OVERUNDER
                        break;
                    case "disp3-1":
                        this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_SPECIAL
                        this.game.kind = managerConst.Game.GAME_KIND_1X2
                        break;
                    case "disp3-2":
                        this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_SPECIAL
                        this.game.kind = managerConst.Game.GAME_KIND_HANDICAP
                        break;
                    case "disp3-3":
                        this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_SPECIAL
                        this.game.kind = managerConst.Game.GAME_KIND_OVERUNDER
                        break;
                    case "disp4-1":
                        this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_LIVE
                        this.game.kind = null
                        break;
                }
                this.getSportsGame();
            },
            changeOrderby() {
                if (this.ob == null) {
                    this.setOrderByString()
                } else if (this.ob.indexOf('count') !== -1) {
                    this.orderBy = this.orderByBetCountStringDESC;
                } else {
                    this.orderBy = this.orderByBetCashStringDESC;
                }
                this.getSportsGame()
            },
            changeStatus() {
                switch (this.selectedStatus) {
                    case "wait":
                        this.game.status = managerConst.Game.STATUS_CHECKING
                        break
                    case "open":
                        this.game.status = managerConst.Game.STATUS_OPEN
                        break
                    case "start":
                        this.game.status = managerConst.Game.STATUS_STARTING
                        break
                    case "end":
                        this.game.status = managerConst.Game.STATUS_END
                        break
                }
                this.pageNum = 1
                this.setOrderByString()
                this.getSportsGame();
            },
            changeIsInplay() {
                if (this.selectedIsinplay === 'inplay') {
                    this.game.leagueId = null;
                    this.game.gameTypeId = null;
                    this.game.displayPosition = null;
                    this.game.kind = null;
                    this.game.isinplay = managerConst.Game.INPLAY_YES
                } else {
                    this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_1x2;
                    this.game.kind = managerConst.Game.GAME_KIND_1X2;
                    this.game.isinplay = managerConst.Game.INPLAY_NOT
                }
                this.search();
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getSportsGame();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getSportsGame();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.game.ids = [];
                this.multipleSelection.map(g => {
                    this.game.ids.push(g.id)
                });
            },
            getSportsGame() {
                this.getLeagues();
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if (null == this.orderBy) {
                    this.setOrderByString()
                }
                getSportsGameList(this.game, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    this.pageTotal = res.data.total;
                    this.gameList = res.data.data;
                    this.gameList.map(cg => {
                        if (cg.scores != null && cg.scores.indexOf("{") !== -1) {
                            cg.scores = JSON.parse(cg.scores);
                        }
                    })
                    loadingInstance.close();
                })
            },
            getLeagues() {
                getOnGamingLeagues(this.game).then(res => {
                    this.leagueList = res.data.data
                })
            },
            leagueSelected(leagueId) {
                this.visible = false
                this.game.leagueId = leagueId
                this.getSportsGame()
            },
            gameTypeSelected() {
                this.visible = false
                this.game.leagueId = null;
                this.getSportsGame();
                this.getLeagues();
            },
            updateGameStatus(status) {
                if (this.game.ids.length === 0) {
                    this.$message({
                        showClose: false,
                        duration: 1500,
                        message: '경기를 선택하세요',
                        type: 'error'
                    });
                    return false;
                }
                let record = {};
                record.ids = this.game.ids;
                record.status = status;
                updateStatus(record).then(res => {
                    if (res.data.success) {
                        this.getSportsGame();
                        this.$message({
                            showClose: false,
                            message: '상태변경이 완료되였습니다',
                            duration: 1500,
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 5000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })

            },
            cancelGame(result) {
                if (this.game.ids.length === 0) {
                    this.$message({
                        showClose: false,
                        duration: 1500,
                        message: '경기를 선택하세요',
                        type: 'error'
                    });
                    return false;
                }

                let cont = '';
                if (result === managerConst.Game.GAME_RESULT_CANCELED) {
                    cont = '취소'
                }
                if (result === managerConst.Game.GAME_RESULT_SPECIAL) {
                    cont = '적특'
                }
                this.$confirm('선택하신 경기를 ' + cont + ' 처리 하시겠습니까?', '취소/적특 처리', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let record = {};
                    record.ids = this.game.ids;
                    record.gameResult = result;
                    record.status = managerConst.Game.STATUS_END
                    updateGameResultCancel(record).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '업데이트 완료되였습니다'
                            });
                            this.getSportsGame()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })


                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '업데이트 취소되였습니다'
                    });
                });
            },
            initGame(id, h, a) {
                this.$confirm(h + ' vs ' + a + '경기를 초기화 하시겠습니까?', '경기 초기화', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    initGame(id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '초기화 완료되였습니다'
                            });
                            this.getSportsGame()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '초기화 취소되였습니다'
                    });
                });
            },
            confirmResult() {
                this.$confirm('입력된 결과를 적용 하시겠습니까?', '결과적용', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    confirmResult().then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '결과적용 완료되였습니다'
                            });
                            this.liquidation();
                            this.getSportsGame()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '결과적용 취소되였습니다'
                    });
                });
            },
            gameResultReset() {
                if (this.game.ids.length === 0) {
                    this.$message({
                        showClose: false,
                        duration: 1500,
                        message: '경기를 선택하세요',
                        type: 'error'
                    });
                    return false;
                }

                this.$confirm('선택하신 경기결과를 초기화 하시겠습니까?', '결과초기화', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let record = {};
                    record.ids = this.game.ids;
                    gameResultReset(record).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '경기결과 초기화 완료되였습니다'
                            });
                            this.getSportsGame()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '경기결과 초기화 취소되였습니다'
                    });
                });
            },
            reuploadgame(id, h, a) {
                this.$confirm(h + ' vs ' + a + '경기를 재등록 하시겠습니까?', '경기 재등록', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    reuploadgame(id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '재등록 요청이 완료되였습니다'
                            });
                            this.getSportsGame()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '재등록 요청이  취소되였습니다'
                    });
                });
            },
            liquidation() {
                this.$confirm('베팅정산 신청을 하시겠습니까?', '베팅정산 신청', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    liquidation().then(res => {
                    })
                    this.$message({
                        type: 'success',
                        duration: 1000,
                        message: '베팅 정산신청 완료되였습니다'
                    });

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '결과적용 취소되였습니다'
                    });
                });
            },
            updateGameDisplay(display) {
                if (this.game.ids.length === 0) {
                    this.$message({
                        showClose: false,
                        duration: 1500,
                        message: '경기를 선택하세요',
                        type: 'error'
                    });
                    return false;
                }
                this.$confirm('선택하신 경기를 삭제 하시겠습니까?', '경기삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let record = {};
                    record.ids = this.game.ids;
                    record.display = display
                    updateGameDisplay(record).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: ' 완료되였습니다'
                            });
                            this.getSportsGame()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: ' 취소되였습니다'
                    });
                });
            },
            uploadGameExcel() {
                this.gameExceluploadDialog = true
            },
            editGame(id, mode) {
                this.$sportsEditToast({gameId: id, mode: mode})
            },
            showSportsBetInfo(game) {
                this.$sportsBetInfoToast({game: game})
            },
            showMessagePanel(game) {
                this.gameMessage.game = game
                this.gameMessage.gameId = game.id
                this.gameMessage.content = null
                this.sendMessageDialog = true
            },
            message2better() {
                sendMessageFromGame(this.gameMessage).then(res => {
                    console.log(res)
                    if (res.data.success) {
                        this.$message({
                            showClose: true,
                            duration: 1500,
                            message: '쪽지전송이 완료되였습니다',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 2000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            setScore(game) {
                if (game.isinplay === managerConst.Game.INPLAY_NOT) {
                    this.$sportsScoreToast({gameId: game.id})
                }
                if (game.isinplay === managerConst.Game.INPLAY_YES) {
                    this.$inplayScoreToast({gameId: game.id})
                }

            },
            submitUpload() {
                uploadFile(this.uploadAction, this.excelFile).then(res => {
                    this.gameExceluploadDialog = false
                    this.excelFile = null;
                    this.fileList = []
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: res.data.data
                        });
                        this.getSportsGame()
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            fileChanged(file, fileList) {
                this.excelFile = file.raw;
            },
            uploadSuccess(res, file, fileList) {
                console.log(res)
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            onEditorChange(editor) {
                this.gameMessage.content = editor.html;
            },
            editLeague(id, mode) {
                this.$leagueEditToast({'leagueId': id, 'mode': mode})
            },
            setPath() {
                if (this.$route.query.selectedStatus) {
                    this.selectedStatus = this.$route.query.selectedStatus;
                    switch (this.selectedStatus) {
                        case "wait":
                            this.game.status = managerConst.Game.STATUS_CHECKING
                            break
                        case "open":
                            this.game.status = managerConst.Game.STATUS_OPEN
                            break
                        case "start":
                            this.game.status = managerConst.Game.STATUS_STARTING
                            break
                        case "end":
                            this.game.status = managerConst.Game.STATUS_END
                            break
                    }
                }

                this.selectedIsinplay = this.$route.query.selectedIsinplay;
                if (this.selectedIsinplay === 'sports') {
                    this.game.display = managerConst.ENABLE
                    this.game.displayPosition = managerConst.Game.GAME_DISPLAYPOSITION_1x2
                    this.game.kind = managerConst.Game.GAME_KIND_1X2
                    this.game.isinplay = managerConst.Game.INPLAY_NOT
                    this.game.onlyBet = managerConst.NOT
                    this.game.ids = []
                } else {
                    this.game.display = managerConst.ENABLE
                    this.game.displayPosition = null
                    this.game.kind = null
                    this.game.isinplay = managerConst.Game.INPLAY_YES
                    this.game.onlyBet = managerConst.NOT
                    this.game.ids = []
                }
            }
        },
        created() {
            this.setPath();

            getGameTypeList().then(res => {
                this.gameTypeList = res.data.data;
            })
            this.getSportsGame();

            //일반스포츠 결과 입력후
            this.$bus.$on('sportsScoreSuccess', (calbc) => {
                if (calbc.gameId > 0) {
                    this.gameList.map(g => {
                        if (g.id === calbc.gameId) {
                            g.homeTeamScore = calbc.hs
                            g.awayTeamScore = calbc.as
                            g.gameResult = calbc.gameResult
                        }
                    })
                } else {
                    this.$message({
                        type: 'error',
                        duration: 1000,
                        message: '스코입력 실패'
                    });
                }
            })
            //실시간 스포츠 결과 입력후
            this.$bus.$on('inplayScoresInserted', (calbc) => {
                console.log(calbc)
                if (calbc.issuccess) {
                    this.getSportsGame()
                } else {
                    this.$message({
                        type: 'error',
                        duration: 2000,
                        message: calbc.msg
                    });
                }
            })

            this.$bus.$on('update_sports_end', (calbc) => {
                this.getSportsGame()
            })
        },
        watch: {
            $route: function (to, from) {
                this.setPath();
                this.getSportsGame();
            },
            currentTypeId() {
                this.gameList.forEach(game => {
                    this.setIsShow(game)
                })
            },
            checkedLeagueList() {
                if (this.checkedLeagueList.length === 0) {
                    this.gameList.forEach(game => {
                        this.setIsShow(game)
                    })
                } else {
                    let ids = []
                    this.checkedLeagueList.forEach(league => {
                        ids.push(league.id)
                    })
                    this.gameList.forEach(game => {
                        game.isShow = ids.indexOf(game.leagueId) !== -1;
                    })

                }

            }
        }
    }
</script>

<style scoped>

</style>